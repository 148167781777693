import React from 'react'
import ReactTyped from 'react-typed'
import appImgone from '../assets/images/assets/app-img-500.png'
import GplayBadge from '../assets/images/assets/256x256-google.png'
// import appleBadge from '../assets/images/assets/256x256-apple.png'

const Hero = () => {
  const heroServices = ['you in case of emergency','medicine delivery for you', 'your valuable life'];
  return (
    <div className='w-full h-full bg-white' id='home'>
      {/* Call to action */}
      <div className='flex md:px-8 pb-20 xl:justify-center text-black'>
        <div className='md:w-[650px] pt-[112px] md:pt-40 px-10 flex flex-col justify-center'>
        <h1 className='text-3xl md:text-5xl leading-8 font-black mt-[18px]'>We are <strong className='text-sThemeColor-accent'>Hellomed</strong></h1>
          <h3 className='text-lg font-bold pt-10 uppercase'>We Take care of</h3>
          <ReactTyped className='text-2xl md:text-3xl leading-8 font-black mt-[18px] uppercase' strings={heroServices} typeSpeed={60} backSpeed={140} loop/>
          <h2 className='text-sm font-medium md:text-base leading-relaxed mt-[18px] mb-[18px]'>
          Our expert doctors offer professional medication consultation and personalized care for a healthier life.
          </h2>
          <div className='flex gap-6 items-center'>
            <a href="https://play.google.com/store/apps/details?id=rw.hellomed.hellomed.app&hl=en&gl=US" rel="noreferrer"><img src={GplayBadge} alt="download img google" className='w-[200px] transform duration-300 hover:scale-110'/></a>
            {/* <a href="/#download_apple"><img src={appleBadge} alt="download img apple" className='w-[200px] transform duration-300 hover:scale-110'/></a> */}

          </div>
        </div>
        {/* hero image visible on tablet desktop */}
        <img className='hidden lg:block pt-36' src={appImgone} alt="App Img" />
      </div>
    </div>
  )
}

export default Hero