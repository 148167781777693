import React from 'react'
import ServicesCard from './minicomponents/ServicesCard'
import MedicineCard from './minicomponents/MedicineCard'
const ServicesSection = () => {
  return (
    <section className='text-black px-8 py-10 flex flex-col items-center justify-center bg-sThemeColor-sectionBg' id='services' >
        <div className='mx-auto'>
            <h2 className='text-3xl font-black uppercase text-center lg:text-left'>Our Specialized Treatment Areas</h2>
            <hr className='border-2 border-[#b8b5b5] w-[200px] my-8 mx-auto items-center rounded-md hover:border-[#e5f5e5]'/>
            <div className='max-w-[572px] mt-5 font-medium'>
                <p className='text-sm text-center'>Explore the diverse range of conditions we expertly address and treat with utmost care.</p>
            </div>
        </div>
        <div className='my-5 pt-5 flex flex-wrap justify-center gap-3 md:gap-6 '>
                <ServicesCard/>
        </div>
        <div className='mt-8 mx-auto'>
            <h2 className='text-3xl font-black uppercase text-center lg:text-left'>Our packages.</h2>
            <hr className='border-2 border-[#b8b5b5] w-[200px] my-8 mx-auto items-center rounded-md hover:border-[#e5f5e5]'/>
            <div className='max-w-[572px] mt-5 font-medium'>
                <p className='text-sm text-center'>Choose the right package that suites your needs</p>
            </div>
        </div>
        <div className='my-5 pt-5 flex flex-wrap justify-center gap-3 md:gap-6 '>
          <MedicineCard/>
        </div>
    </section>
  )
}

export default ServicesSection