import React, { useState } from 'react'
import GifAboutDesktop from '../assets/images/assets/about-video.gif'
const AboutSection = () => {
    const [toggle, setToggle] = useState(1)
    function updateToggle(id)
    {
        setToggle(id)
    }
  return (
    <section className='text-black px-8 py-10 flex flex-col items-center lg:flex-row bg-white h-full my-20' id='about' >
      <div className='mt-8 mx-auto'>
        <h2 className='text-3xl font-black uppercase text-center lg:text-left'>about us</h2>
        <nav className='h-8 my-10 '>
          <ul className='text-sm font-bold flex justify-center lg:justify-start gap-4 uppercase'>
            <li className='hover:text-sThemeColor-accent hover:border-sThemeColor-accent hover:border-b-2 pb-3 cursor-pointer' onClick={()=>updateToggle(1)} onMouseEnter={()=>updateToggle(1)}>About Hellomed</li>
            <li className='hover:text-sThemeColor-accent hover:border-sThemeColor-accent hover:border-b-2 pb-3 cursor-pointer' onClick={()=>updateToggle(2)} onMouseEnter={()=>updateToggle(2)}>About our services</li>
            <li className='hover:text-sThemeColor-accent hover:border-sThemeColor-accent hover:border-b-2 pb-3 cursor-pointer' onClick={()=>updateToggle(3)} onMouseEnter={()=>updateToggle(3)}>How to Download</li>
          </ul>
        </nav>
        <div className='max-w-[640px] mt-5 font-medium'>
          <p className={toggle === 1 ? 'flex text-sm' : 'hidden'}>
          HelloMed is a mobile app available on the App Store and Play Store, offering a convenient online platform for accessing healthcare services. 
          With HelloMed, you can easily request and book doctor appointments, purchase medicines and research a certain illness or treatment right from your mobile device. 
          Experience the convenience of managing your healthcare needs anytime, anywhere.
          </p>
          <p className={toggle === 2 ? 'flex text-sm' : 'hidden'}>
          HelloMed provides a range of services to enhance your healthcare experience. We offer medicine delivery for conditions such as HIV/AIDS, Tuberculosis, Hepatitis B&C, and Diabetes. 
          By registering your medications with HelloMed, you can rely on us to ensure timely delivery to your doorstep. We understand the importance of uninterrupted access to essential medications, 
          and we strive to simplify the process for you.</p>
          <p className={toggle === 3 ? 'flex text-sm' : 'hidden'}>
          To access HelloMed's comprehensive healthcare solutions, simply download the HelloMed app from the App Store or Play Store. The app is available for both iOS and Android devices. 
          Once you have the app installed, you can explore its user-friendly interface, book doctor appointments, and register your medications for convenient medicine delivery. 
          Take control of your healthcare journey by downloading HelloMed today.</p>
        </div>
        <div className='mt-4 flex justify-center md:justify-start'>
        <a className='w-[300px] h-9 p-6 text-lg font-medium bg-sThemeColor-accent rounded-lg flex items-center justify-center text-white hover:font-bold hover:scale-105 transform duration-200 gap-3 text-center' href="/#home">Download App</a>
        </div>
      </div>
      <img className='mr-auto hidden lg:block w-[300px]' src={GifAboutDesktop} alt="smart phone gif" />
    </section>  )
}

export default AboutSection