import React from 'react';
import { AiOutlineMail } from 'react-icons/ai';
import { BsTelephone } from 'react-icons/bs';
import { CgWebsite } from 'react-icons/cg';
import { FaFacebookF } from 'react-icons/fa';
import { IoLogoWhatsapp, IoLogoYoutube, IoLogoInstagram, IoLogoTwitter } from 'react-icons/io';

const ContactInfo = () => {
	return (
		<div className="max-w-[480px] h-[410px] bg-black rounded-md shadow-md p-4">
			<div className="px-8 pt-10">
				<h2 className="text-lg font-black">How to contact us?</h2>
				<ul className="">
					<li className="mt-2 flex items-center gap-3">
						<span>
							<AiOutlineMail />
						</span>
						hellomedrwanda@gmail.com
					</li>
					<li className="mt-2 flex items-center gap-3">
						<span>
							<BsTelephone />
						</span>
						+250785424960
					</li>
					<li className="mt-2 flex items-center gap-3">
						<span>
							<CgWebsite />
						</span>
						Kigali, Rwanda
					</li>
				</ul>
				<p className="mt-4">
					Get the answers of some questions about our services by contacting us via call or social Media
				</p>
				{/* Social Links */}
				<ul className="mt-4 flex gap-4">
					<li className="cursor-pointer">
						<div className="w-8 h-8 rounded-full bg-sThemeColor-accent hover:bg-white flex items-center justify-center group ease-in duration-100">
							<IoLogoWhatsapp
								className="text-black group-hover:text-sThemeColor-accent  ease-in duration-100"
								size={20}
							/>
						</div>
					</li>
					<li className="cursor-pointer">
						<div className="w-8 h-8 rounded-full bg-sThemeColor-accent hover:bg-white flex items-center justify-center group ease-in duration-100">
							<IoLogoYoutube
								className="text-black group-hover:text-sThemeColor-accent ease-in duration-100"
								size={20}
							/>
						</div>
					</li>
					<li className="cursor-pointer">
						<div className="w-8 h-8 rounded-full bg-sThemeColor-accent hover:bg-white flex items-center justify-center group ease-in duration-100">
							<FaFacebookF
								className="text-black group-hover:text-sThemeColor-accent ease-in duration-100"
								size={20}
							/>
						</div>
					</li>
					<li className="cursor-pointer">
						<div className="w-8 h-8 rounded-full bg-sThemeColor-accent hover:bg-white flex items-center justify-center group ease-in duration-100">
							<IoLogoInstagram
								className="text-black group-hover:text-sThemeColor-accent ease-in duration-100"
								size={20}
							/>
						</div>
					</li>
					<li className="cursor-pointer">
						<div className="w-8 h-8 rounded-full bg-sThemeColor-accent hover:bg-white flex items-center justify-center group ease-in duration-100">
							<IoLogoTwitter
								className="text-black group-hover:text-sThemeColor-accent ease-in duration-100"
								size={20}
							/>
						</div>
					</li>
				</ul>
				<p className="mt-4"> © Copyright Hellomed Rwanda</p>
			</div>
		</div>
	);
};

export default ContactInfo;
