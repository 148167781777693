import React from 'react'
import LinksDesktop from './minicomponents/LinksDesktop'
import Logo from './../assets/logo-icon.png'


const FooterSection = () => {
  return (
    <section className="hidden w-full py-6 px-16 lg:px-24 md:flex items-center justify-between shadow-md bg-white text-black">
        <a href="/">
        <img className='w-10' src={Logo} alt="Hellomed Logo" />
        </a>

        {/* Footer Nav */}
         <LinksDesktop/>
    </section>
    )
}

export default FooterSection