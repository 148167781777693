import React, { useEffect, useState } from 'react'
import { IoLogoTwitter } from'react-icons/io'
import { FaLinkedinIn } from 'react-icons/fa'


const Team = () => {
    const [staffMember, setMember] = useState([]);
    useEffect(()=>{
        fetchMembers();
    },[]);
    const fetchMembers = async()=>{
        try {
            const response = await fetch("https://api.hellomed.rw/web/data/");
            const data = await response.json()
            setMember(data.staff)
        } catch (error) {
            console.log('Error', error)
        }
    };


  return (
            <>
            {
            staffMember.map((teamMember)=>(
            <div key={staffMember.id}>
                <div className='items-center flex justify-center'>
                    <img src={teamMember.image_url} className='rounded-full max-h-[260px] h-48 w-48' alt={teamMember.name} />
                </div>
                <div className='mt-5 items-center text-center'>
                    <h2 className='font-bold text-md'>{teamMember.name}</h2>
                </div>
                <div className='mt-3 items-center text-center'>
                    <h3 className='font-medium'>{teamMember.position}</h3>
                </div>
                <div className='flex gap-2 justify-center my-5'>
                    <div className='bg-sThemeColor-aboutSectionColor p-2 rounded-sm shadow-sm hover:scale-105 transition-all duration-300 '>
                        <FaLinkedinIn size={20}/>
                    </div>
                    <div className='bg-sThemeColor-aboutSectionColor p-2 rounded-sm shadow-sm hover:scale-105 transition-all duration-300 '>
                        <IoLogoTwitter  size={20} />
                    </div>
                </div>
            </div>
            ))}
            </>
    )
}

export default Team