import React, {useState, useEffect} from 'react'
import Accordion from './minicomponents/Accordion'

const FaqsSection = () => {
    const [faqs, setFaqs] = useState([]);
    useEffect(()=>{
        fetchFaqs();
    },[]);

    const fetchFaqs = async() => {
        try {
            const response = await fetch('https://api.hellomed.rw/web/data/');
            const data= await response.json();
            setFaqs(data.FAQs);
        } catch (error) {
            console.error('Errror Fetching Faqs Data', Error)
        }
    };
  return (
    <section className='w-full h-auto py-10 bg-sThemeColor-sectionBg text-black m-0 p-4 md:p-10 flex flex-col items-center justify-center' id='faqs'>
        <div className='mt-8 mx-auto'>
            <h2 className='text-3xl font-black uppercase text-center lg:text-left'>FAQs</h2>
            <hr className='border-2 border-[#b8b5b5] w-[200px] my-8 mx-auto items-center rounded-md hover:border-[#e5f5e5]'/>
            <div className='max-w-[572px] mt-5 font-medium'>
                <p className='text-sm text-center'>Get answers of the most asked questions</p>
            </div>
        </div>
        <div className='my-5 pt-5 flex flex-wrap justify-center gap-3 md:gap-6 '>
            <div className="max-w-[700px] w-full">
                {
                    faqs.map((item, id)=>{
                        return <div className="bg-white p-4 rounded-md m-1" key={id}>
                            <Accordion question={item.question} answer={item.answer}/>
                        </div>
                    })
                }
            </div>
        </div>
 
    </section>
    )
}

export default FaqsSection