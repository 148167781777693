import React from 'react'
import Team from './minicomponents/Team'

const TeamSection = () => {
  return (
    <section id='team' className='text-black py-10 flex flex-col'>
        <div className='mx-auto'>
            <div>
            <h2 className='text-3xl font-black uppercase text-center'>Meet Our Team</h2>
            </div>
            <hr className='border-2 border-[#b8b5b5] w-[200px] my-8 mx-auto items-center rounded-md hover:border-[#e5f5e5]'/>
            <div className='max-w-[572px] mt-5 font-medium'>
                <p className='text-sm text-center'>Discover Our Dedicated Team, We are Committed to providing Excellent services.</p>
            </div>
        </div>
        <div className='pt-10 my-3 text-black items-center mx-auto flex flex-wrap gap-10 justify-center md:max-w-[1100px]'>
        <Team/>
        </div>
    </section>
  )
}

export default TeamSection