import React, {useState} from 'react'

const ContactForm = () => {
  const [formView, showForm] = useState(1)
  const [formData, setFormdata] = useState({
    name:'',
    phone:'',
    email:'',
    message:''
  });

  const handleChange = (e) => {
    setFormdata({
      ...formData, // spread the existing formData
      [e.target.name]: e.target.value // update the specific property
    });
  };

  const handleSubmit = async(e) => {
    e.preventDefault();
    try {
      const response = await fetch("https://api.hellomed.rw/web/contact/", {
        method:'POST',
        headers: {
          'Content-Type':'application/json'
        }, 
        body:JSON.stringify(formData)
      });
      function updateForm(id)
      {
        showForm(id)
      }

      if (response.ok) {
        updateForm(0)
      }
      else
      {
        console.log("not okay")
      }
    } catch (error) {
      console.error('Error', error)
    }

   
  }

  return (


    <div>
      <form className={ formView === 1? 'w-full mt-5 flex flex-col gap-6 text-green text-black font-normal border-black' : 'hidden'} onSubmit={handleSubmit}>
        <input type="text" name="name" value={formData.name}  placeholder="Enter your name" onChange={handleChange}  className="p-2 text-normal bg-transparent border-b  border-black" required />
        <input type="text" name="phone" value={formData.phone}  onChange={handleChange} placeholder="Enter phone" className="p-2 text-normal bg-transparent border-b  border-black" required  />
        <input value={formData.email} name="email" className="p-2 text-normal bg-transparent border-b border-black" type="text" placeholder="Enter e-mail" onChange={handleChange} required />
        <textarea value={formData.message} name="message" className="h-[72px] p-2 text-normal bg-transparent border-b border-black" placeholder="Enter your message" onChange={handleChange} required></textarea>
        <button type='submit' className="w-40 p-2 text-sm text-center uppercase border rounded-lg text-white bg-sThemeColor-accent  ease-in-out duration-200 hover:scale-105 hover:font-bold">Submit</button>
    </form>
    <div className={formView === 1 ? 'hidden': "flex my-5"}>
      <p>Your Message has successfully reached us, we shall get back on you asap!</p>
    </div>
    </div>
    
)
}

export default ContactForm