import React from 'react'
import HeroCards from './HeroCards'

const MicroServices = () => {
  return (
      <div className='w-full bg-sThemeColor-sectionBg py-10 px-10 flex flex-wrap justify-center gap-3 md:gap-6'>
            <HeroCards/>
      </div>

    )
}

export default MicroServices