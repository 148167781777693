import React, { useState, useEffect } from 'react';

const DataCount = () => {
	const [data, setData] = useState(null);
	useEffect(() => {
		const fetchData = async () => {
			try {
				const response = await fetch('https://api.hellomed.rw/web/data/');
				const responseData = await response.json();
				setData(responseData);
			} catch (error) {
				console.error('error', error);
			}
		};
		fetchData();
	}, []);
	if (!data) {
		return <div>no data</div>;
	}
	const { doctors_count, patients_count, requests_count, deliveries_count } = data;
	return (
		<div className="flex flex-col gap-4 md:flex-row">
			<div className="w-60 flex flex-col gap-3">
				<p className="text-5xl font-bold">2500+</p>
				<p className="text-md">Patients treated</p>
			</div>
			<div className="w-60 flex flex-col gap-3 flex-wrap">
				<p className="text-5xl font-bold">4+</p>
				<p className="text-md">Daily Physicians</p>
			</div>
			<div className="w-60 flex flex-col gap-3 flex-wrap">
				<p className="text-5xl font-bold">40+</p>
				<p className="text-md">Daily consultations</p>
			</div>
			<div className="w-60 flex flex-col gap-3 flex-wrap">
				<p className="text-5xl font-bold">1000+</p>
				<p className="text-md">Lab tests reffered to partner hospitals</p>
			</div>
			<div className="w-60 flex flex-col gap-3 flex-wrap">
				<p className="text-5xl font-bold">0</p>
				<p className="text-md">Medicine delivered</p>
			</div>
		</div>
	);
};

export default DataCount;
