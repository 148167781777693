import React from 'react'
import DataCount from './minicomponents/DataCount'
import TestmonialCard from './minicomponents/TestmonialCard'

const Outcome = () => {
  return (
    <section className='w-full h-full p-8 md:py-16 bg-sThemeColor-outcomeSectionBg text-white m-0 flex flex-col gap-6 items-center justify-center' id='outcomes'>  
       <div id='outcome' className='items-center flex flex-col justify-center text-center gap-10'>
        <h2 className='font-bold text-2xl md:text-4xl md:w-[1000px]'>We have improved the accessibility of health services for patients.</h2>
        <p className='font-medium text-xl'>OUTCOME</p>
        <DataCount/>
       </div>
       <div  id='testmonial' className='items-center flex flex-col justify-center text-center gap-10 p-4' >
        <p className='font-medium text-xl uppercase'>Testimonials</p>
            <div className='flex gap-6 flex-col md:flex-row'>
                    <TestmonialCard/>
            </div>
       </div>
    </section>  
    )
}

export default Outcome