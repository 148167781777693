// importing icons

import {
	MdMedicalServices,
	MdOutlineDeliveryDining,
	MdOutlineCorporateFare,
	MdOutlineFamilyRestroom,
} from 'react-icons/md';
import { TbCalendarStats } from 'react-icons/tb';
import {
	FaUserNurse,
	FaBacteria,
	FaWheelchair,
	FaUserInjured,
	FaMicroscope,
	FaHandSparkles,
	FaSyringe,
	FaHeadSideCoughSlash,
} from 'react-icons/fa';
import { AiFillEye } from 'react-icons/ai';

// importing images partners

import Acumen from '../src/assets/partners/acumen.png';
import Tony from '../src/assets/partners/tonny.png';
import Dafrapharma from '../src/assets/partners/dafrapharma.jpeg';
import Ovo from '../src/assets/partners/ovo.png';

// import images awards

import DubaiChamber from '../src/assets/partners/dubai-chamber.jpg';
import Sanofi from '../src/assets/partners/hellomed-sanofi.png';
import YouthImpact from '../src/assets/partners/y4i.png';
import VivaTech from '../src/assets/partners/viva.png';
import AhaiEmory from '../src/assets/partners/emory.png';
import MarketMind from '../src/assets/partners/market-minds.png';
// links
export const links = [
	{
		title: 'Home',
		path: '/',
	},
	{
		title: 'About',
		path: '/#about',
	},
	{
		title: 'Services',
		path: '/#services',
	},
	{
		title: 'Team',
		path: '/#team',
	},
	{
		title: 'Partners',
		path: '/#partners',
	},
	{
		title: 'FAQs',
		path: '/#faqs',
	},
	{
		title: 'Contact',
		path: '/#contact',
	},
];

// hero services

export const hello_card_data = [
	{
		title: 'REQUESTS & BOOKINGS',
		descriptions:
			'Should you ever experience discomfort or unwellness, please do not hesitate to request our services.',
		icon: <MdMedicalServices />,
	},
	{
		title: 'EXPERIENCED DOCTORS',
		descriptions:
			'We collaborate with highly skilled doctors hailing from prestigious hospitals, ensuring the highest level of expertise and experience.',
		icon: <FaUserNurse />,
	},
	{
		title: 'MEDICINE DELIVERY',
		descriptions:
			'We provide convenient medication delivery services, ensuring that the medicines you require are promptly delivered to your doorstep at your convenience.',
		icon: <MdOutlineDeliveryDining />,
	},
	{
		title: 'MEDICATION RECORDS',
		descriptions:
			'Rest assured, your medical records are in safe hands. We meticulously maintain and securely store all your records for future reference and convenience.',
		icon: <TbCalendarStats />,
	},
];

// about services

export const service_data = [
	{
		title: 'Common Issues',
		descriptions: ['Fever', 'Cough', 'Cold', 'Flu', 'Headached', 'migraines'],
		icon: <FaBacteria size={30} />,
	},
	{
		title: 'Ear, Nose, Throat',
		descriptions: ['Sore throat/Shep throat', 'Ear infections', 'Sinus infection', 'Nosal conjestion'],
		icon: <FaSyringe size={30} />,
	},
	{
		title: 'Gastrointestinal',
		descriptions: ['Uoset Stomach', 'Nausea', 'Vomiting', 'Diarrhoe', 'indigestion'],
		icon: <FaMicroscope size={30} />,
	},
	{
		title: 'Other injuries',
		descriptions: ['Joint & back pain', 'Cuts & Scrapes', 'Strains & Sprains', 'Urine & Vaginal infections'],
		icon: <FaUserInjured size={30} />,
	},
	{
		title: 'Respiratory',
		descriptions: ['Asthma', 'Infections', 'Cold', 'Bronchitis', 'Seasonal allergies'],
		icon: <FaHeadSideCoughSlash size={30} />,
	},
	{
		title: 'Skin',
		descriptions: ['Rashes', 'Dry skin', 'Acne', 'Scabies'],
		icon: <FaHandSparkles size={30} />,
	},
	{
		title: 'Health screening',
		descriptions: [
			'Blood pressure screaning & councelling',
			'Cholesterol counceling',
			'Travel medicine',
			'Sports physicals',
			'Other physicals',
		],
		icon: <FaWheelchair size={30} />,
	},
	{
		title: 'Eye',
		descriptions: ['Eye infections', 'Cold', 'Eye irritation', 'Pink eye', 'Stye'],
		icon: <AiFillEye size={30} />,
	},
];

// medicine data

export const medicine_data = [
	{
		title: 'For Employers, organization, corporate',
		descriptions: [
			'No leaving early or day off',
			'Services are available 24/7 all day',
			'Reduced co-payments',
			'Appropriate utilization of insurance',
			'Only the necessary tests',
			'Increased employee satisfaction and retention',
		],
		icon: <MdOutlineCorporateFare size={30} />,
	},
	{
		title: 'For Individuals and families',
		descriptions: [
			'No more long hospital waits',
			'Reduced healthcare related costs (transport)',
			'Improved care experience',
			'Only the necessary costs',
			'Providing more convenience',
			'Available 24/7 all day',
			'Improved post-diagnosis follow-up',
		],
		icon: <MdOutlineFamilyRestroom size={30} />,
	},
	{
		title: 'For special needy patients (NCDs)',
		descriptions: [
			'Regular check-ups (Screening)',
			'Available at all time on patients need',
			'Reduced costs compared to existing costs',
			'One-time fee (Payment) option available',
		],
		icon: <FaWheelchair size={30} />,
	},
	{
		title: 'For Insurances',
		descriptions: [
			'Reduced out patients commercially driven claims',
			'Provide competitive insurance rates to clients',
			'Control or manage medical insurance loses',
			'Clients satisfaction and retention',
		],
		icon: <FaUserInjured size={30} />,
	},
];

// faqs _ local

export const faqs_data = [
	{
		id: 1,
		question: 'question 1',
		answer:
			'Lorem Ipsum Neque porro quisquam est qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit...',
	},
	{
		id: 2,
		question: 'question 2',
		answer:
			'Lorem Ipsum Neque porro quisquam est qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit...',
	},
	{
		id: 3,
		question: 'question 3',
		answer:
			'Lorem Ipsum Neque porro quisquam est qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit...',
	},
];

export const partners = [
	{
		id: 1,
		title: 'ACUMEN EAST AFRICA',
		link: 'https://acumen.org/east-africa/',
		image: Acumen,
	},
	{
		id: 2,
		title: 'Tony Elumelu Foundation',
		link: 'https://www.tonyelumelufoundation.org/',
		image: Tony,
	},
	{
		id: 3,
		title: 'Dafra Pharma',
		link: 'https://www.dafrapharma.com/',
		image: Dafrapharma,
	},
	{
		id: 4,
		title: 'OVOV',
		link: 'https://ondernemersvoorondernemers.be/en/home',
		image: Ovo,
	},
];

export const awards_data = [
	{
		id: 1,
		image: DubaiChamber,
		link: 'https://www.dubaichamber.com/en/home/',
	},
	{
		id: 2,
		image: Sanofi,
		link: 'https://www.sanofi.com/en',
	},
	{
		id: 3,
		image: YouthImpact,
		link: '#',
	},
	{
		id: 4,
		image: VivaTech,
		link: 'https://app.vivatechnology.com/partners/8af88e0d-bd65-e911-b49e-28187816b50e',
	},
	{
		id: 5,
		image: AhaiEmory,
		link: 'https://www.ahiaemory.org/newsstories/2020/4/6/2019-innovator-appears-on-cnbc-africa',
	},
	{
		id: 6,
		image: MarketMind,
		link: '#',
	},
];

export const web_data = [
	{
		doctors_count: 3,
		requests_count: 13,
		deliveries_count: 0,
		testimonials: [],
		staff: [
			{
				id: 1,
				name: 'Aloys Rukundo',
				image_url: 'https://api.hellomed.rw/media/uploads/staff_images/IMG-20191024-WA0004.jpg',
				position: 'Founder & Chief Executive Officer',
				caption: 'The founder of Hellomed',
			},
			{
				id: 2,
				name: 'Nkurunziza Emmanuel',
				image_url: 'https://api.hellomed.rw/media/uploads/staff_images/photo_pasport.png',
				position: 'Doctor',
				caption:
					'Nkurunziza Emmanuel 5 years experience Medical Doctor, currently doing masters of public health very passionate at seeing everyone access to health services as human right.',
			},
			{
				id: 3,
				name: 'Ndashimye Serge Jean Paul',
				image_url: 'https://api.hellomed.rw/media/uploads/staff_images/_GOL2572.jpg',
				position: 'Doctor',
				caption:
					'Ndashimye Serge Jean Paul is a medical doctor by training with 4 years of clinical experience in patient management, health promotion and hospital management. He holds a masters in Vaccinology and Drug Development from University of Siena, Italy.',
			},
			{
				id: 4,
				name: 'Inès Ineza',
				image_url:
					'https://api.hellomed.rw/media/uploads/staff_images/WhatsApp_Image_2022-03-23_at_3.46.29_PM.jpeg',
				position: 'COO',
				caption:
					'Inès Ineza is the COO of Hellomed. She previously worked for Ecobank Rwanda in the Operations &Internal control department. She holds a Bachelor’s degree in Management.',
			},
			{
				id: 5,
				name: 'Gakyeli Eric',
				image_url: 'https://api.hellomed.rw/media/uploads/staff_images/gak.jpg',
				position: 'Head IT Officer',
				caption:
					'Gakyeli Eric is the Head IT officer at Hellomed. He has over 7 years working experience in IT working in various institutions including BNR',
			},
			{
				id: 7,
				name: 'Joyeuse Uwamurera',
				image_url:
					'https://api.hellomed.rw/media/uploads/staff_images/dcd80b64-7827-4959-9cfd-9287a04467e3.JPG',
				position: 'Nurse',
				caption:
					"Joyeuse Uwamurera is a nurse with a bachelor's degree in nursing obtained from the University of Gitwe in the southern province of Rwanda.",
			},
		],
		services: [],
		gallery: [],
		FAQs: [
			{
				question: 'How do we install the mobile App',
				answer: 'Open your play store and search for hellomed, tap on the app and install',
			},
		],
		doctors: [],
	},
];
