import React from 'react'

import {links} from '../../data'

function LinksDesktop() {
  return (
    <>
    
    <nav className='hidden xl:w-full lg:flex justify-center text-black'>
    
        <ul className='text-center uppercase flex gap-8'>
        {
         links.map(({title, path}, index)=>{
            return(
                <li  key={index}  className='text-sm font-[600] pb-8 md:pb-0'><a className='hover:underline underline-offset-8' href={path}>{title}</a></li>
            )
         })   
        }
        </ul>
      </nav>
      <a className='hidden lg:block w-48 p-3 text-sm font-bold text-center uppercase hover:text-white hover:scale-105 border-2 text-black border-sThemeColor-accent rounded-lg hover:font-bold hover:bg-sThemeColor-accent hover:border-sThemeColor-accent ease-in-out duration-200' href="https://forms.fillout.com/t/6RSxpmTPAfus">Work with us</a>
    </>
  )
}

export default LinksDesktop