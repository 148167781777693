import React from 'react'
import { awards_data } from '../../data'

const Awards = () => {
  return (
    <>
        {
            awards_data.map(({id,image, link})=>{
                return(
                    <div  key={id} className='w-[400px] border-1 border-b-sThemeColor-bgBlack sm:w-36 md:w-60 h-[180px] md:h-44 p-4 rounded-xl bg-sThemeColor-aboutSectionColor  justify-between flex flex-col text-black shadow-xs hover:shadow-lg drop-shadow-lg transform duration-200 hover:scale-110 min-w-[250px] min-h-[180px] gap-3'>
                        <a href={link} target="_blank" rel="noreferrer">
                        <img src={image} alt='award' className='h-36 rounded-md w-full' />
                        </a> 
                    </div>
                )
            })
        }
        </>
    )
}

export default Awards