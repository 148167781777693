import React, {useState, useEffect} from 'react'

const TestmonialCard = () => {
    const [data, setData] = useState([]);

    useEffect(()=>{
        const fetchData = async ()=>{
            try {
                const response = await fetch("https://api.hellomed.rw/web/data/");
                const responseData = await response.json();
                setData(responseData.testimonials);
            } catch (error) {
                console.error("Error:", error);
            }
        };
        fetchData();
    },[]);
  return (
<>
{
    data.map((item, id) =>{
        return(
            <div key={id} className='bg-[#41b2cb] p-6 rounded-md flex flex-col gap-4 md:w-[400px] justify-between'>
                <p>" {item.testimony} "</p>
                <div className='flex items-center justify-center m-3'>
                    <div>
                        <img src={item.image} alt={item.name} className='h-24 w-24 rounded-full'/>
                    </div>
                    <div className='flex flex-col items-start m-3'>
                        <h2 className='font-bold text-black'>{item.name}</h2>
                        <p className='font-medium'>{item.title}</p>
                    </div>
                </div>
            </div>
        )
    })
}

</>
    )
}

export default TestmonialCard