import React from 'react'
import Partners from './minicomponents/Partners'
import Awards from './minicomponents/Awards'
import { FiChevronRight, FiChevronLeft } from "react-icons/fi";

const PartnerSection = () => {
  
  const scrollRight = () =>
  {
    document.getElementById('content').scrollLeft +=200
  }
  const scrollLeft = () =>
  {
    document.getElementById('content').scrollLeft -=200
  }



  return (
    <section className='text-black px-8 py-10 flex flex-col items-center justify-center'>
         <div className='mx-auto'>
            <h2 className='text-3xl font-black uppercase text-center lg:text-left'>Investors and Partners</h2>
        </div>
        <div className='my-5 pt-5 flex flex-wrap justify-center gap-3 md:gap-6 '>
            <Partners/>
        </div>
        <div className='mt-8 mx-auto'>
            <h2 className='text-3xl font-black uppercase text-center lg:text-left'>Awards, and Recognitions</h2>
        </div>
        <div className='my-5 pt-5 flex justify-center items-center gap-3 md:gap-3 max-w-[400px] md:max-w-[1200px]'>
          <div>
            <button onClick={scrollLeft} className="p-2 m-2 rounded-full bg-sThemeColor-aboutSectionColor  hover:scale-110 hover:shadow-md transition duration-200">
              <FiChevronLeft/>
            </button>
          </div>
          <div id='content' className='carousel p-4 flex items-center justify-start overflow-x-auto scroll-smooth  scrollbar-hide gap-6'>
          <Awards/>
          </div>
          <div>
            <button onClick={scrollRight} className="p-2 m-2 rounded-full bg-sThemeColor-aboutSectionColor  md:hover:scale-110 hover:shadow-md transition duration-200" >
              <FiChevronRight className='hover:scale-110'/>
            </button>
          </div>
        </div>
    </section>
    )
}

export default PartnerSection