import React from 'react'
import {hello_card_data} from '../../data'

const HeroCards = () => {
  return (
  <>
  {
       hello_card_data.map(({title,descriptions,icon},index)=>{
        return(
          <div key={index} className='w-[400px] border-1 border-b-sThemeColor-bgBlack sm:w-36 md:w-60 h-[300px] md:h-44 p-4 rounded-xl bg-white flex flex-col text-black shadow-xs hover:shadow-lg  transform duration-200 hover:scale-110 min-w-[250px] min-h-[250px] gap-3'>
            <div className='bg-sThemeColor-aboutSectionColor p-2 w-8 rounded-md items-center shadow-none h-8 hover:scale-125 transform duration-300'>
              {icon}
            </div>
            <h2 className='text-md font-bold mt-2'>{title}</h2>
            <p className='text-xs font-medium text-left leading-relaxed mt-2'>{descriptions}</p>
          </div>
        )
       })
  }
  </>
    )
}

export default HeroCards