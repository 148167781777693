import React from 'react'
import { partners } from '../../data'

const Partners = () => {
  return (
        <>
        {
            partners.map(({id,image, title, link})=>{
                return(
                    <div key={id} className='w-[300px] border-1 border-b-sThemeColor-bgBlack sm:w-36 md:w-60 h-[320px] md:h-44 p-4 rounded-xl bg-sThemeColor-aboutSectionColor justify-between flex flex-col text-black shadow-xs hover:shadow-lg drop-shadow-lg transform duration-200 hover:scale-110 min-w-[250px] min-h-[300px] gap-3'>
                        <img src={image} alt={title} className='h-36 rounded-md w-full' />
                        <h2 className='text-md font-bold mt-2'>{title}</h2>
                        <a href={link} target="_blank" rel="noreferrer">
                            <div className='w-full p-2 text-center rounded-md text-black bg-white ease-in-out duration-200'>
                                <h2 className='text-md font-bold'> Visit Website </h2>
                            </div>
                        </a> 
                    </div>
                )
            })
        }
        </>

    )
}

export default Partners