import React from 'react';
import { medicine_data } from '../../data';

const MedicineCard = () => {
	return (
		<>
			{medicine_data.map((item, index) => {
				return (
					<div
						key={index}
						className="w-[300px] sm:w-36 md:w-60 h-[300px] md:h-[24rem] p-4 rounded-xl bg-white flex flex-col text-black shadow-xs hover:shadow-lg  transform duration-200 hover:scale-110 min-w-[250px] min-h-[260px] gap-3"
					>
						<div className="bg-sThemeColor-aboutSectionColor p-2 w-12 rounded-md items-center shadow-none h-12 hover:scale-125 transform duration-300">
							{item.icon}
						</div>
						<h2 className="text-md font-bold mt-2">{item.title}</h2>
						<p className="text-xs font-medium text-left leading-relaxed mt-2">
							<ul className="list-disc items-center mx-6">
								{item.descriptions.map((description, index) => {
									return <li key={index}>{description}</li>;
								})}
							</ul>
						</p>
					</div>
				);
			})}
		</>
	);
};

export default MedicineCard;
