import React from 'react'
import ContactForm from './minicomponents/ContactForm'
import ContactInfo from './minicomponents/ContactInfo'

const ContactSection = () => {
  return (
        <section id="contact" className='w-full p-4 mt-20 lg:px-16 py-10 lg:mt-0 flex flex-wrap flex-col lg:flex-row items-center justify-around gap-4 '>
            <div className="p-8 text-black bg-sThemeColor-sectionBg rounded-md shadow-md">
                <h2 className="text-xl font-black">Have any question?</h2>
                <h3 className="mt-5 font-black">just Leave us a message, we shall get in touch with you</h3>
                <ContactForm/>
            </div>
            <ContactInfo/>
        </section>

    )
}

export default ContactSection